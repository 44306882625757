import axios from "axios";
import qs from "qs";

// import router from '@/router/'
// import {
//   getToken
//   // setToken
// } from "@/utils/auth";
// import message from "@/utils/pnotify";
let baseUrl = "http://dev.waterss.cn:9150";
// let baseUrl = "https://waterss.cn";
// let baseUrl = `/`;
// let baseUrl = `/`;
const _https = axios.create({
  baseURL: baseUrl
});
_https.interceptors.request.use(
  config => {
    // if (getToken()) {
    //     config.headers['authen'] = getToken()
    // }
    //只针对get方式进行序列化
    if (config.method === "get") {
      config.paramsSerializer = function(params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }
    if (localStorage.getItem("token")) {
      config.headers["X-Access-Token"] = localStorage.getItem("token");
    }
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
_https.interceptors.response.use(
  response => {
    if (
      response.headers["Content-type"] == "multipart/form-data;charset=UTF-8"
    ) {
      return response;
    }
    return response;
  },
  error => {
    Promise.reject(error);
    console.log(error);
  }
);

async function https(options) {
  const response = await _https(options);
  console.log(response);
  // if (response.data && response) { // 判断是否存有token,并且response有返回token 以旧换新 并且重新发送请求
  // 设置新token
  // setToken(response.data)
  // setToken()
  // 重新发送请求接收数据
  // if (getToken() && Number(response.status_code) === 103) {
  //     return await http(options)
  // }
  // }

  //102token过期，重新登陆
  //104Token无效
  //101Token为空
  // if (response.headers) {
  //     if (response.headers["content-type"] == "text/html;charset:utf-8;") {
  //         let str = response.headers["content-disposition"];
  //         return {
  //             data: response.data,
  //             name: str.split("filename*=utf-8''")[1]
  //         }
  //     }
  // }
  if (response.status == 200) {
    let result = response.data;
    if (result.data?.token && result.code == 0) {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("userName", result.data.nick_name);
    }
    return result;
  }
  if (response.status == 401) {
    localStorage.removeItem("token");
    let host = window.location.origin;
    window.location.replace(host);
  }
  if (response.status == 200 || response.error == 0) {
    return response.data;
  } else {
    if (response.status == 500) {
      throw new Error(response.error);
    }
  }
  //
  // if (Number(response.status_code) === 102 || Number(response.status_code) === 104 || Number(response.status_code) === 101 || Number(response.status_code) === 105) {
  //    message.error('请重新登录')
  //     setToken('')
  //     location.reload()
  // } else {
  //     //105账号密码错误
  //     if (Number(response.status_code) >= 200 && Number(response.status_code) < 300) {
  //         return response.content
  //     } else if (Number(response.status_code) == 403) {
  //         message.error(response.message)
  //         router.push('/403')
  //         throw new Error(response.message)
  //     } else {
  //         message.error(response.message)
  //         throw new Error(response.message)
  //     }
  // }
}

export default https;
