<template>
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form-model style="background: #ffffff;min-height:900px;padding-top: 20px;"
                    ref="ruleForm"
                    :model="formData"
                    :rules="rules"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">

        <a-row class="form-row" :gutter="16">
          <a-col :lg="18" :md="16" :sm="24">

            <a-form-model-item ref="method"
                               prop="method"
                               label="检验方法名称"
                               :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                               :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
              <a-input
                v-model="formData.method"
                placeholder="检验方法名称" />
            </a-form-model-item>
            <a-form-model-item ref="code"
                               prop="code"
                               label="检验方法代码"
                               :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                               :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
              <a-input
                v-model="formData.code"
                placeholder="检验方法代码" />
            </a-form-model-item>
            <a-form-model-item ref="standards"
                               prop="standards"
                               label="检验方法标准"
                               :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                               :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
              <a-input
                v-model="formData.standards"
                placeholder="检验方法标准" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item
          :wrapperCol="{ span: 24 }"
          style="text-align: center"
        >
          <a-button htmlType="submit" type="primary" @click="saveMethod">提交</a-button>
          <a-button style="margin-left: 8px" @click="goBack">返回</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
</template>

<script>
import { addMethod } from '@/api/quality/item'

export default {
  name: 'BaseForm',
  data () {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      formData: {
        code: '',
        method: '',
        standards: ''
      },
      roleList: [],
      rules: {
        code: [{ required: true, message: "请输入检测方法代码", trigger: "blur" }],
        method: [{ required: true, message: "请输入检测方法名称", trigger: "blur" }],
        standards: [{ required: true, message: "请输入检测标准", trigger: "blur" }]
      }
    }
  },
  methods: {
    // 接口请求
    saveMethod () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          addMethod(this.formData).then(response => {
            if (response.code === 0){
              this.$message.info('添加成功')
              this.$router.back()
            } else {
              this.$message.error('添加失败')
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>
