import http from "@/plugins/httpss";

const api = {
  itemPage: '/api/water-quality/qualityDetectionInfo/page',
  itemList: '/api/water-quality/qualityDetectionInfo/list',
  itemDetail: '/api/water-quality/qualityDetectionInfo/getDetail',
  itemAdd: '/api/water-quality/qualityDetectionInfo/addDetail',
  itemUpdate: '/api/water-quality/qualityDetectionInfo/updateDetail',
  itemDel: '/api/water-quality/qualityDetectionInfo/deleteDetail',
  methodAdd: '/api/water-quality/qualityDetectionMethod/add',
  methodList: '/api/water-quality/qualityDetectionMethod/list',
  methodUpdate: '/api/water-quality/qualityDetectionMethod/update',
  methodDelete: '/api/water-quality/qualityDetectionMethod/delete',
  fast: '/api/water-quality/qualityFastConfiguration/list',
  fastDetail: '/api/water-quality/qualityFastConfiguration/getDetail',
  fastDelete: '/api/water-quality/qualityFastConfiguration/deleteDetail',
  fastAdd: '/api/water-quality/qualityFastConfiguration/addDetail',
  fastUpdate: '/api/water-quality/qualityFastConfiguration/updateDetail'
}

export default api

export function getItemPage (parameter) {
  return http({
    url: api.itemPage,
    method: 'get',
    params: parameter
  })
}

export function getItemList (parameter) {
  return http({
    url: api.itemList,
    method: 'get',
    params: parameter
  })
}

export function addCenterInfo (parameter) {
  return http({
    url: api.centeradd,
    method: 'post',
    data: parameter
  })
}

export function getFastList (parameter) {
  return http({
    url: api.fast,
    method: 'get',
    params: parameter
  })
}

export function getFastDetail (parameter) {
  return http({
    url: api.fastDetail,
    method: 'get',
    params: parameter
  })
}

export function deleteFast (parameter) {
  return http({
    url: api.fastDelete,
    method: 'get',
    params: parameter
  })
}

export function addFast (parameter) {
  return http({
    url: api.fastAdd,
    method: 'post',
    data: parameter
  })
}

export function updateFast (parameter) {
  return http({
    url: api.fastUpdate,
    method: 'post',
    data: parameter
  })
}

export function getItemInfo (parameter) {
  return http({
    url: api.itemDetail,
    method: 'get',
    params: parameter
  })
}

export function addItemInfo (parameter) {
  return http({
    url: api.itemAdd,
    method: 'post',
    data: parameter
  })
}

export function updateItemInfo (parameter) {
  return http({
    url: api.itemUpdate,
    method: 'post',
    data: parameter
  })
}

export function delItemInfo (parameter) {
  return http({
    url: api.itemDel,
    method: 'get',
    params: parameter
  })
}


export function getMethodList (parameter) {
  return http({
    url: api.methodList,
    method: 'get',
    params: parameter
  })
}

export function addMethod (parameter) {
  return http({
    url: api.methodAdd,
    method: 'post',
    data: parameter
  })
}

export function updateMethod (parameter) {
  return http({
    url: api.methodUpdate,
    method: 'post',
    data: parameter
  })
}

export function deleteMethod (parameter) {
  return http({
    url: api.methodDelete,
    method: 'get',
    params: parameter
  })
}

const fullText = function (keyword, text) {
  var hlen = text.length;
  var nlen = keyword.length;
  if (nlen > hlen) {
    return false;
  }
  if (nlen === hlen) {
    return keyword === text;
  }
  outer: for (var i = 0, j = 0; i < nlen; i++) {
    var nch = keyword.charCodeAt(i);
    while (j < hlen) {
      if (text.charCodeAt(j++) === nch) {
        continue outer;
      }
    }
    return false;
  }
  return true;
}
